import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="h-10 py-1 bg-dark">
        <div className="text-center ">
          
          <p className="font-poppins font-semibold text-md text-light-cream">
            ©2025 - Kiran B L
          </p>
        </div>
    </footer>
  );
};

export default Footer;